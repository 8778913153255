body {
    background-color: #fff;
    background-image: url(images/bg-body.jpg);
    background-repeat: repeat-x;
    margin: 0;
    font-family: 'Archivo Narrow', sans-serif;
    font-size: 16px;
    overflow-x: hidden
}

.clear {
    clear: both
}

.clear:before,
.clear:after {
    content: " ";
    display: table
}

.clear {
    *zoom: 1
}

A:link {
    color: #251A2D;
    text-decoration: underline
}

A:visited {
    color: #251A2D;
    text-decoration: underline
}

A:active {
    color: #251A2D;
    text-decoration: underline
}

A:hover {
    color: #900107;
    text-decoration: underline
}

a,
img {
    border: none
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-bottom: 15px;
    margin-top: 0;
    font-family: 'Archivo Narrow', sans-serif;
    font-weight: 400
}

h1 {
    font-size: 22px;
    font-family: 'Oswald', sans-serif;
    text-transform: uppercase
}

h2 {
    font-size: 16px;
    color: #910008
}

h3 {
    font-size: 16px;
    color: #910008
}

h4 {
    font-size: 14px
}

h5 {
    font-size: 14px
}

h6 {
    font-size: 14px
}

#main-header {
    max-width: 1400px;
    width: 100%;
    margin-left: auto;
    margin-right: auto
}

#main {
    max-width: 1400px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    position: relative
}

.post-container {
    width: 1200px;
    padding-left: 120px;
    float: left;
    min-height: 400px
}

#footer {
    width: 1400px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    padding-top: 20px
}
#main-header {
    box-sizing: border-box;
    position: relative;
}
#header {
    max-width: 1210px;
    width: 100%;
    margin: 0 auto;
    background-image: url(images/bg-header.png);
    background-repeat: no-repeat;
    background-position: top right;
    box-sizing: border-box;
    padding-right: 20px;
    padding-left: 20px;
}

#logo {
    width: 35%;
    float: left
}

#header-content {
    width: 60%;
    float: right;
}

#header-content3 {
    width: 200px;
    height: 80px;
    float: right;
    padding-top: 23px;
    text-align: right;
    font-size: 16px;
    font-family: 'Archivo Narrow', sans-serif;
    padding-right: 45px
}

#header-content3 a:link {
    color: #242414;
    text-decoration: none
}

#header-content3 a:link {
    color: #242414;
    text-decoration: none
}

#header-content3 a:link {
    color: #910009;
    text-decoration: none
}

#header-content1-wrapper {
    width: 192px;
    float: right;
    margin-top: 10px;
    background-image: url(images/icon-phone.png);
    background-repeat: no-repeat
}

#header-content1 {
    font-size: 30px;
    color: #000;
    font-family: 'Roboto Condensed', sans-serif;
    float: right;
    text-align: right
}

#header-content2 {
    width: 210px;
    font-size: 25px;
    color: #910008;
    float: right;
    text-align: right;
    padding-top: 40px
}

#header-content2 a:link {
    color: #910008;
    text-decoration: none
}

#header-content2 a:visited {
    color: #910008;
    text-decoration: none
}

#header-content2 a:hover {
    color: #000;
    text-decoration: none
}

#navigation {
    width: 947px;
    height: 56px;
    float: right;
    background-image: url(images/bg-navigation.jpg);
    background-repeat: no-repeat;
    background-position: top right;
    position: relative;
    top: -3px;
}

#framed {
    width: 760px;
    padding-bottom: 10px;
    float: left
}

#framed iframe {
    width: 100%!important
}

#mobile-navigation {
    display: none;
}

#mobile-hide {
    visibility: hidden
}

#home-button {
    width: 100px;
    float: left;
    font-size: 18px;
    height: 44px;
    font-family: 'Archivo Narrow', sans-serif;
    padding-top: 18px;
    padding-bottom: 14px;
    background-image: url(images/bg-navitem.png);
    background-repeat: no-repeat;
    background-position: top right
}

#home-button a:link {
    color: #fff;
    text-decoration: none
}

#home-button a:visited {
    color: #fff;
    text-decoration: none
}

#home-button a:hover {
    color: #C5BDAE;
    text-decoration: none
}

#home-button-hide {
    width: 100px;
    height: 44px;
    float: left
}

#slideshow-wrapper {
    width: 100%;
    height: 507px;
    float: left
}

#slideshow {
    width: 100%;
    height: 507px;
    overflow: hidden;
    float: left
}

#slideshow img {
    width: 100%!important;
    max-height: 507px
}

.soliloquy-container.soliloquy-slide-horizontal .soliloquy-direction-nav li .soliloquy-next,
.soliloquy-container .soliloquy-direction-nav li .soliloquy-next {
    height: 60px!important;
    margin-top: -8px;
    right: 10px;
    position: absolute;
    text-indent: -9999px;
    top: 50%;
    width: 68px!important;
    z-index: 30
}

.soliloquy-container.soliloquy-slide-horizontal .soliloquy-direction-nav li .soliloquy-prev,
.soliloquy-container .soliloquy-direction-nav li .soliloquy-prev {
    height: 60px!important;
    left: 10px;
    margin-top: -8px;
    position: absolute;
    text-indent: -9999px;
    top: 50%;
    width: 68px!important;
    z-index: 30
}

.soliloquy-next {
    background-image: url(images/slide-next.png)!important;
    background-position: bottom!important;
    background-repeat: no-repeat!important
}

.soliloquy-prev,
.soliloquy-next {
    width: 68px!important;
    height: 60px!important
}

.soliloquy-prev {
    background-image: url(images/slide-prev.png)!important;
    background-position: bottom!important;
    background-repeat: no-repeat!important
}

.soliloquy-caption {
    width: 525px!important;
    right: 10%!important;
    bottom: 15px!important;
    left: auto!important;
    background-color: #910008!important;
    padding-top: 25px!important;
    padding-left: 25px!important;
    padding-right: 25px!important;
    padding-bottom: 5px!important
}

.soliloquy-container .soliloquy-caption .soliloquy-caption-inside {
    -ms-filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#4C000000, endColorstr=#4C000000);
    background: none!important;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#4C000000, endColorstr=#4C000000);
    line-height: 18px;
    margin: 0;
    padding: 10px;
    text-align: left!important;
    text-shadow: 0 1px 0 rgba(0, 0, 0, .3);
    zoom: 1
}

.font1 {
    font-size: 34px;
    color: #fff;
    font-family: 'Archivo Narrow', sans-serif
}

.font2 {
    font-size: 16px;
    color: #C5BDAE;
    font-family: 'Archivo Narrow', sans-serif
}

.font3 {
    font-size: 18px;
    color: #fff;
    font-family: 'Archivo Narrow', sans-serif;
    padding-top: 10px
}

#row1 {
    width: 1254px;
    height: 332px;
    float: left;
    padding-left: 146px;
    background-image: url(images/bg-row1.jpg);
    background-repeat: no-repeat
}

#row1-left {
    width: 554px;
    height: 204px;
    float: left;
    font-family: 'Archivo Narrow', sans-serif;
    font-size: 16px;
    padding-right: 55px;
    border-right: 1px solid #C5BDAE;
    padding-top: 40px
}

#row1-right {
    width: 520px;
    height: 230px;
    float: left;
    font-family: 'Archivo Narrow', sans-serif;
    font-size: 16px;
    padding-left: 47px;
    background-image: url(images/bg-row1-right.jpg);
    background-repeat: no-repeat;
    background-position: top right;
    padding-top: 56px
}

#row1-right ul {
    list-style-type: none;
    padding-left: 0;
    margin-left: 0;
    margin-top: 10px
}

#row1-right li {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 15px
}

.date {
    font-size: 20px
}

.single-portfolio-container {
    width: 90%;
    float: left;
    background-color: #FFF;
    padding: 2% 5%
}

.single-portfolio-container img {
    width: 70%;
    float: right;
    padding-left: 3%;
    margin-bottom: 10px;
    margin-top: 6px
}

#map-icon img {
    width: 50px;
    height: 50px;
    float: left;
    padding-left: 0
}

.page-content,
.single-post-container,
#comments {
    width: 1280px;
    padding-left: 120px;
    float: left;
    min-height: 600px;
    padding-top: 35px;
    background-image: url(images/bg-content-bottom.jpg);
    background-repeat: no-repeat;
    background-position: left bottom;
    padding-bottom: 70px
}

.page-content ul {
    list-style-type: none;
    padding-left: 0;
    margin-left: 0;
    margin-top: 10px;
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2
}

.page-content li {
    background: url(images/bullet.jpg) left center no-repeat;
    padding-left: 25px;
    margin-bottom: 5px
}

#news-photo {
    width: 400px;
    float: right;
    text-align: right
}

#news ul {
    list-style-type: none;
    padding-left: 0;
    margin-left: 0;
    margin-top: 10px;
    columns: 2;
    -webkit-columns: 1;
    -moz-columns: 1
}

#news li {
    background: none;
    padding-left: 0;
    margin-bottom: 30px
}

#footer {
    width: 100%;
    float: left;
    height: 82px;
    background-color: #000;
    font-family: 'Archivo Narrow', sans-serif
}

#footer a:link {
    color: #fff;
    text-decoration: none
}

#footer a:visited {
    color: #fff;
    text-decoration: none
}

#footer a:hover {
    color: #C5BDAE;
    text-decoration: none
}

#footer2-wrapper {
    width: 1400px;
    margin-left: auto;
    margin-right: auto
}

#footer2 {
    width: 1207px;
    height: 55px;
    padding-top: 15px;
    float: left;
    padding-left: 120px;
    color: #C5BDAE
}

#footer2-left {
    width: 470px;
    float: left;
    text-align: left;
    font-size: 14px
}

#sitemap ul {
    columns: 1;
    -webkit-columns: 1;
    -moz-columns: 1;
    text-transform: uppercase
}

#sitemap li {
    margin-bottom: 15px;
    background: url(images/bullet2.jpg) left top no-repeat
}

#footer2-right {
    width: 635px;
    float: right;
    text-align: right;
    font-size: 18px;
    padding-right: 80px
}

#footer2-right-sm {
    font-size: 15px;
    padding-top: 10px
}

#our-people-row1 {
    width: 1184px;
    float: left;
    border-bottom: 10px solid #000;
    padding-top: 40px;
    margin-bottom: 20px
}

#our-people-quote {
    width: 420px;
    height: 120px;
    text-align: center;
    float: right;
    padding: 28px 20px 8px 20px;
    background-color: #910008;
    font-size: 20px;
    color: #fff;
    font-family: 'Oswald', sans-serif;
    line-height: 26px;
    margin-left: 65px
}

#our-people-box {
    width: 370px;
    height: 243px;
    float: left;
    background-color: #C5BDAE;
    margin-right: 37px;
    margin-bottom: 33px;
    padding: 14px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    position: relative
}

#our-people-image {
    float: left;
    width: 155px;
    height: 217px;
    padding-right: 20px
}

#our-people-box img {
    float: left;
    width: 155px;
    height: auto;
    max-height: 217px
}

#our-people-text {
    float: left;
    width: 165px;
    height: 217px
}

#our-people-box h2 {
    font-size: 18px
}

#our-people-box a:link {
    color: #000;
    text-decoration: none
}

#our-people-box a:visited {
    color: #000;
    text-decoration: none
}

#our-people-box a:hover {
    color: #910008;
    text-decoration: none
}

#our-people-box h2 a:link {
    color: #910008;
    text-decoration: none
}

#our-people-box h2 a:visited {
    color: #910008;
    text-decoration: none
}

#our-people-box h2 a:hover {
    color: #910008;
    text-decoration: none;
    font-weight: 700
}

#our-people-left {
    width: 245px;
    padding: 20px;
    background-color: #C5BDAE;
    float: left;
    margin-right: 40px;
    text-align: right;
    margin-bottom: 50px;
    margin-top: 40px
}

hr {
    color: #000;
    background-color: #000;
    border: none;
    height: 1px
}

#our-people-left img {
    float: left;
    width: 244px;
    height: auto;
    margin-bottom: 15px
}

#vcard {
    float: right;
    width: 75px;
    height: 30px;
    margin-left: 150px;
    margin-bottom: 20px
}

#vcard-white {
    float: left;
    width: 75px;
    height: 30px;
    margin: 3px 30px 0 0;
    text-decoration: underline
}

.plus {
    width: 30px;
    height: 30px;
    font-size: 50px;
    position: absolute;
    bottom: 35px;
    right: 15px;
    font-family: "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", "DejaVu Sans", Verdana, sans-serif;
    font-weight: 700
}

.plus a:link {
    color: #ffffff!important;
    text-decoration: none
}

.plus a:visited {
    color: #ffffff!important;
    text-decoration: none
}

.plus a:hover {
    color: #910008!important;
    text-decoration: none
}

.plus2 {
    width: 30px;
    height: 30px;
    font-size: 50px;
    position: absolute;
    bottom: 35px;
    right: 15px;
    font-family: "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", "DejaVu Sans", Verdana, sans-serif;
    font-weight: 700
}

.plus2 a:link {
    color: #ffffff!important;
    text-decoration: none
}

.plus2 a:visited {
    color: #ffffff!important;
    text-decoration: none
}

.plus2 a:hover {
    color: #C5BDAE!important;
    text-decoration: none
}

#our-people-content {
    width: 440px;
    float: left;
    padding-top: 40px
}

#subnav {
    width: 260px;
    padding: 135px 35px;
    background-color: #910008;
    float: right;
    text-align: right;
    background-image: url(images/bg-content.jpg);
    background-repeat: no-repeat;
    background-position: right top;
    position: relative;
    margin-right: 70px
}

#menu-our-people li {
    font-size: 18px;
    color: #FFF;
    font-family: 'Archivo Narrow', sans-serif;
    margin: 0
}

#subnav h2 {
    font-size: 22px;
    color: #000;
    font-family: 'Oswald', sans-serif
}

#menu-our-people a:link {
    color: #FFF;
    text-decoration: none
}

#menu-our-people a:visited {
    color: #FFF;
    text-decoration: none
}

#menu-our-people a:hover {
    color: #C5BDAE;
    text-decoration: none
}

#menu-our-people .current-menu-item a:link {
    color: #C5BDAE;
    text-decoration: none;
    font-weight: 700
}

#menu-our-people .current-menu-item a:visited {
    color: #C5BDAE;
    text-decoration: none;
    font-weight: 700
}

#subnav ul {
    margin: 0;
    padding: 0
}

#subnav li {
    list-style-type: none;
    margin: 0;
    padding: 0
}

#services-content-left {
    width: 790px;
    float: left
}

#services-our-people {
    width: 322px;
    float: right;
    margin-right: 70px;
    margin-bottom: 33px
}

.services-our-people-box {
    width: 322px;
    float: left;
    background-color: #C5BDAE;
    padding: 14px 14px 5px 14px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    position: relative;
    margin-bottom: 7px
}

.services-our-people-image {
    float: left;
    width: 103px;
    height: 128px;
    padding-right: 20px
}

.services-our-people-box img {
    float: left;
    width: 103px;
    height: auto;
    max-height: 128px
}

#content-callout {
    height: 0;
    visibility: hidden
}

#content-callout-show {
    width: 620px;
    min-height: 160px;
    float: left;
    padding: 22px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    background-color: #910008;
    position: relative
}

#content-callout-show .plus2 {
    bottom: 45px!important;
    right: 25px!important
}

#content-callout-show h2 {
    font-size: 22px;
    color: #fff;
    font-family: 'Oswald', sans-serif
}

#callout-box-line {
    width: 576px;
    position: absolute;
    bottom: 15px;
    border-bottom: 1px solid #000;
    padding-top: 10px
}

.post {
    width: 900px;
    float: left
}

#portfolio {
    width: 100%;
    float: left
}

#portfolio img {
    width: 150px;
    height: auto;
    float: left
}

.portfolio-item {
    background-color: #C5BDAE;
    padding: 20px;
    width: 370px;
    height: 142px;
    float: left;
    margin-right: 37px;
    margin-bottom: 33px;
    padding: 14px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    position: relative
}

.portfolio-image {
    float: left;
    width: 155px;
    height: 112px;
    padding-right: 20px
}

.pp_play {
    visibility: hidden
}

#news-box {
    width: 240px;
    float: right;
    padding: 20px;
    border: 1px solid silver;
    margin-left: 110px;
    margin-right: 90px;
    margin-bottom: 20px
}

#news-box ul {
    list-style-type: none!important;
    padding-left: 0;
    margin-left: 0;
    margin-top: 10px
}

#news-box li {
    list-style-type: none!important;
    padding-left: 0;
    margin-bottom: 15px
}

.alignleft,
.post img.alignleft {
    display: inline;
    float: left;
    margin-right: 20px;
    margin-top: 4px
}

.alignright,
.post img.alignright {
    display: inline;
    float: right;
    clear: right;
    margin-left: 20px;
    margin-top: 4px
}

.aligncenter,
.post img.aligncenter {
    text-align: center;
    clear: both;
    display: block;
    margin-left: auto;
    margin-right: auto
}

.post img.alignleft,
.post img.alignright,
.post img.aligncenter {
    margin-bottom: 20px
}

.post ul {
    margin-bottom: 10px
}

ol.commentlist {
    list-style: none;
    margin: 0;
    padding: 0
}

ol.commentlist li {
    border: 1px solid #d5d5d5;
    margin: 0 0 10px;
    padding: 5px 7px 5px 64px;
    position: relative;
    list-style-type: none
}

ol.commentlist li.pingback comment-author {
    padding: 0 170px 0 0;
    background-color: #F6FFED
}

ol.commentlist li div.vcard {
    font-size: .8em;
    line-height: 1.5em
}

ol.commentlist li div.vcard cite.fn {
    font-weight: 700;
    font-size: 1.3em
}

ol.commentlist li div.vcard cite.fn a.url {
    color: #c00;
    text-decoration: none
}

ol.commentlist li div.vcard cite.fn a.url:hover {
    color: #000
}

ol.commentlist li div.vcard img.avatar {
    background: #fff;
    border: 1px solid #aaa;
    padding: 5px;
    left: 7px;
    position: absolute;
    top: 7px;
    margin-left: -60px
}

ol.commentlist li div.comment-meta {
    position: absolute;
    right: 5px;
    text-align: right;
    top: 0;
    font-size: .7em;
    color: #333
}

ol.commentlist li div.comment-meta a {
    color: #666;
    text-decoration: none
}

ol.commentlist li p {
    margin-top: 20px;
    margin-right: 0;
    margin-bottom: 12px;
    margin-left: 0
}

ol.commentlist li ul {
    list-style: square;
    margin: 0 0 12px;
    padding: 0
}

ol.commentlist li div.reply {
    border: 1px solid #999;
    color: #333;
    padding-left: 3px;
    padding-right: 3px;
    text-align: center;
    font-family: helvetica, arial, sans-serif;
    font-size: .8em;
    font-weight: 700;
    background-color: #CCC;
    width: 50px
}

ol.commentlist li div.reply:hover {
    background: #c00;
    border: 1px solid #c00;
    color: #FFF
}

ol.commentlist li div.reply a {
    color: #fff;
    text-decoration: none;
    text-transform: uppercase
}

ol.commentlist li ul.children {
    list-style: none;
    margin: 12px 0 0;
    text-indent: 0
}

ol.commentlist li ul.children li.depth-2 {
    margin: 0 0 3px
}

ol.commentlist li ul.children li.depth-3 {
    margin: 0 0 3px
}

ol.commentlist li ul.children li.depth-4 {
    margin: 0 0 3px
}

ol.commentlist li ul.children li.depth-5 {
    margin: 0 0 3px
}

ol.commentlist li.pingback div.vcard {
    padding: 0 170px 0 0
}

ol.pings li {
    border: 1px solid #d5d5d5;
    margin: 0 0 10px;
    padding: 5px 7px 5px 64px;
    position: relative;
    list-style-type: none;
    line-height: 1.3em
}

ol.pings {
    width: auto;
    font-size: .7em
}

ol.pings h3 {
    font-size: .9em
}

#access {
    display: block;
    float: left;
    width: 780px;
    font-size: 18px;
    height: 44px;
    font-family: 'Archivo Narrow', sans-serif
}

#access ul {
    list-style: none;
    margin: 0;
    padding-left: 0
}

#access li {
    float: left;
    position: relative;
    text-align: center;
    padding-right: 35px;
    padding-top: 18px;
    padding-bottom: 14px;
    background-image: url(images/bg-navitem.png);
    background-repeat: no-repeat;
    background-position: top right
}

#access #menu-menu li a {
    height: 44px
}

#access a {
    color: #fff;
    display: block;
    line-height: 24px;
    padding: 0 10px 0 10px;
    text-decoration: none
}

#access ul ul {
    display: none;
    float: left;
    margin: 0;
    position: absolute;
    left: 0;
    width: 255px;
    z-index: 99999;
    padding-right: 5px;
    padding-top: 5px
}

#access .sub-menu {
    background-image: url(images/bg-nav.png);
    background-repeat: repeat;
    border: 1px solid #7C7C7C;
    margin-top: 13px;
    margin-left: -11px
}

#access .sub-menu a {
    color: #000;
    height: 24px!important
}

#access .sub-menu a:visited {
    color: #000;
    height: 24px!important
}

#access .sub-menu a:hover {
    color: #fff
}

#access ul ul ul {
    left: 100%;
    top: 0
}

#access ul ul a {
    color: #444;
    font-size: 16px;
    text-align: left;
    font-weight: 400;
    height: auto;
    line-height: 1.4em;
    padding: 5px 14px;
    margin-left: 5px;
    margin-bottom: 5px;
    font-weight: 700
}

#access ul ul li {
    padding-right: 0;
    padding-top: 0;
    background-image: none
}

#access li:hover>a,
#access ul ul :hover>a,
#access a:focus {
    color: #000
}

#access li:hover>a,
#access a:focus {
    color: #C5BDAE
}

#access ul li:hover>ul {
    display: block
}

#access .current-menu-item>a,
#access .current-menu-ancestor>a,
#access .current_page_item>a,
#access .current_page_ancestor>a {
    color: #C5BDAE
}

#access li:last-child {
    border-right: 0;
    background-image: none
}

@media all and (max-width:$midwidth) {
    #main-header {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        height: 189px;
    }
    #main {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        position: relative
    }
    .post-container {
        width: 90%;
        padding-left: 5%;
        float: left;
        min-height: 400px
    }
    #footer {
        width: 100%;
        padding-left: 0%;
        padding-top: 1%;
        height: auto
    }
    #footer2-wrapper {
        width: 100%;
        margin-left: auto;
        margin-right: auto
    }
    #footer2 {
        width: 100%;
        height: auto;
        padding-top: 5px;
        margin-left: auto;
        margin-right: auto;
        padding: 0;
        text-align: center;
        color: #C5BDAE
    }
    #footer2-right {
        width: 100%;
        float: left;
        text-align: center;
        font-size: 18px;
        padding-right: 0;
        padding-top: 15px
    }
    #footer2-left {
        width: 90%;
        float: left;
        padding: 0;
        text-align: center;
        font-size: 14px
    }
    #header {
        width: 100%;
        height: 122px;
        position: relative;
        float: left;
        background-image: none;
        background-repeat: no-repeat;
        background-position: top right;
        display: flex;
        align-content: center;
        align-items: center;
    }
    #logo {
        width: 50%;
        height: auto;
        float: left
    }
    #logo img {
        width: 100%;
        max-width: 400px;
        height: auto
    }
    #header-content {
        width: 50%;
        height: auto;
        float: right;
        padding-right: 0
    }
    #header-content3 {
        width: 100%;
        height: auto;
        float: right;
        padding-top: 13px;
        text-align: right;
        font-size: 16px;
        font-family: 'Archivo Narrow', sans-serif;
        padding-right: 0;
        padding-right: 30px
    }
    #header-content1-wrapper {
        width: 100%;
        float: left;
        margin-top: 0;
        background-image: url(images/icon-phone.png);
        background-repeat: no-repeat;
        background-position: right top
    }
    #header-content1 {
        font-size: 20px;
        float: right;
        text-align: left;
        padding-right: 30px
    }
    #header-content2 {
        width: 100%;
        font-size: 15px;
        color: #910008;
        float: right;
        text-align: right;
        padding-top: 0;
        padding-right: 30px
    }
    #navigation {
        visibility: hidden;
        margin-top: 6px;
    }
    #mobile-navigation {
        background-color: #8F0208;
        display: block;
        width: 100%;
        position: absolute;
        top: 154px;
        left: 0;
        z-index: 5;
        box-sizing: border-box;
        padding-left:7%;
        padding-right:7%;
    }
    #mobile-navigation a:link {
        color: #fff;
        text-decoration: none
    }
    #mobile-navigation a:visited {
        color: #fff;
        text-decoration: none
    }
    #mobile-navigation a:hover {
        color: #C5BDAE;
        text-decoration: none
    }
    #uniquename li {
        padding-bottom: 11px
    }
    #uniquename a:link {
        color: #fff;
        text-decoration: none
    }
    #uniquename a:visited {
        color: #fff;
        text-decoration: none
    }
    #uniquename a:hover {
        color: #C5BDAE;
        text-decoration: none
    }
    #framed {
        width: 100%;
        padding-bottom: 20px;
        float: left
    }
    #framed iframe {
        width: 100%!important
    }
    #mobile-hide {
        visibility: hidden;
        height: 0;
        overflow: hidden
    }
    #desktop-hide {
        visibility: visible;
        padding-bottom: 20px;
        width: 90%
    }
    #access {
        display: block;
        float: left;
        width: 100%;
        font-size: 9px;
        height: 44px;
        font-family: 'Archivo Narrow', sans-serif
    }
    #access li {
        padding-right: 3px;
        background-image: none
    }
    #home-button {
        width: 100px;
        float: left;
        font-size: 18px;
        height: 44px;
        font-family: 'Archivo Narrow', sans-serif;
        padding-top: 18px;
        padding-bottom: 14px;
        background-image: none;
        background-repeat: no-repeat;
        background-position: top right;
        position: absolute;
        top: 40px
    }
    #home-button a:link {
        color: #910009;
        text-decoration: none
    }
    #home-button a:visited {
        color: #910009;
        text-decoration: none
    }
    #home-button a:hover {
        color: #C5BDAE;
        text-decoration: none
    }
    #home-button-hide {
        width: 0;
        height: 0;
        float: left
    }
    #slideshow-wrapper {
        width: 100%;
        height: auto;
        float: left;
        overflow: hidden
    }
    #slideshow {
        width: 100%;
        height: auto;
        float: left;
        overflow: hidden
    }
    #slideshow img {
        width: 100%!important;
        max-height: 507px
    }
    .soliloquy-container.soliloquy-slide-horizontal .soliloquy-direction-nav li .soliloquy-next,
    .soliloquy-container .soliloquy-direction-nav li .soliloquy-next {
        height: 60px!important;
        margin-top: -8px;
        right: 10px;
        position: absolute;
        text-indent: -9999px;
        top: 50px!important;
        width: 68px!important;
        z-index: 30
    }
    .soliloquy-container.soliloquy-slide-horizontal .soliloquy-direction-nav li .soliloquy-prev,
    .soliloquy-container .soliloquy-direction-nav li .soliloquy-prev {
        height: 60px!important;
        left: 10px;
        margin-top: -8px;
        position: absolute;
        text-indent: -9999px;
        top: 50px!important;
        width: 68px!important;
        z-index: 30
    }
    .soliloquy-next {
        background-image: url(images/slide-next.png)!important;
        background-position: bottom!important;
        background-repeat: no-repeat!important
    }
    .soliloquy-prev {
        background-image: url(images/slide-prev.png)!important;
        background-position: bottom!important;
        background-repeat: no-repeat!important
    }
    .soliloquy-caption {
        width: 90%!important;
        height: auto!important;
        right: 0%!important;
        bottom: 0px!important;
        left: auto!important;
        background-color: #910008;
        padding-top: 25px!important;
        padding-left: 25px!important;
        padding-right: 25px!important;
        padding-bottom: 5px!important;
        float: left;
        position: relative!important
    }
    .soliloquy-container .soliloquy-caption .soliloquy-caption-inside {
        -ms-filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#4C000000, endColorstr=#4C000000);
        background: none!important;
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#4C000000, endColorstr=#4C000000);
        line-height: 18px;
        margin: 0;
        padding: 10px;
        text-align: left!important;
        text-shadow: 0 1px 0 rgba(0, 0, 0, .3);
        zoom: 1
    }
    .font1 {
        font-size: 34px;
        color: #fff;
        font-family: 'Archivo Narrow', sans-serif
    }
    .font2 {
        font-size: 16px;
        color: #C5BDAE;
        font-family: 'Archivo Narrow', sans-serif
    }
    .font3 {
        font-size: 18px;
        color: #fff;
        font-family: 'Archivo Narrow', sans-serif;
        padding-top: 10px
    }
    #row1 {
        width: 100%;
        height: auto;
        float: left;
        padding-left: 0;
        background-image: url(images/bg-row1.jpg);
        background-repeat: no-repeat
    }
    #row1-left {
        width: 90%;
        height: auto;
        float: left;
        font-family: 'Archivo Narrow', sans-serif;
        font-size: 16px;
        padding-right: 0;
        padding-left: 5%;
        border-right: 0 solid #C5BDAE;
        padding-top: 40px
    }
    #row1-right {
        width: 90%;
        height: auto;
        float: left;
        font-family: 'Archivo Narrow', sans-serif;
        font-size: 16px;
        padding-left: 5%;
        background-image: none;
        padding-top: 56px
    }
    .single-portfolio-container {
        width: 90%;
        float: left;
        background-color: #FFF;
        padding: 5%
    }
    .single-portfolio-container img {
        width: 70%;
        float: right;
        padding-left: 3%;
        margin-bottom: 10px;
        margin-top: 6px
    }
    #map-icon img {
        width: 50px;
        height: 50px;
        float: left;
        padding-left: 0
    }
    .page-content,
    .single-post-container,
    #comments {
        width: 90%;
        padding-left: 5%;
        float: left;
        min-height: 600px;
        padding-top: 35px;
        background-image: url(images/bg-content-bottom.jpg);
        background-repeat: no-repeat;
        background-position: left bottom;
        padding-bottom: 70px
    }
    #our-people-row1 {
        width: 100%;
        float: left;
        border-bottom: 10px solid #000;
        padding-top: 40px;
        margin-bottom: 20px
    }
    #our-people-quote {
        width: 100%;
        height: 120px;
        text-align: center;
        float: right;
        padding: 28px 20px 8px 20px;
        background-color: #910008;
        font-size: 20px;
        color: #fff;
        font-family: 'Oswald', sans-serif;
        line-height: 26px;
        margin-left: 65px
    }
    #our-people-box {
        width: 100%;
        height: 243px;
        float: left;
        background-color: #C5BDAE;
        margin-right: 37px;
        margin-bottom: 33px;
        padding: 14px;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        position: relative
    }
    #our-people-text {
        float: right;
        width: 145px;
        height: 217px
    }
    #our-people-image {
        float: left;
        width: 105px;
        height: 240px;
        padding-right: 10px
    }
    #our-people-box img {
        float: left;
        width: 105px;
        height: auto;
        max-height: 207px
    }
    #subnav {
        visibility: hidden;
        height: 0;
        padding: 0px!important;
        margin: 0
    }
    #news-box {
        visibility: hidden;
        height: 0
    }
    #our-people-box h2 {
        font-size: 18px
    }
    #our-people-box a:link {
        color: #000;
        text-decoration: none
    }
    #our-people-box a:visited {
        color: #000;
        text-decoration: none
    }
    #our-people-box a:hover {
        color: #910008;
        text-decoration: none
    }
    #our-people-box h2 a:link {
        color: #910008;
        text-decoration: none
    }
    #our-people-box h2 a:visited {
        color: #910008;
        text-decoration: none
    }
    #our-people-box h2 a:hover {
        color: #910008;
        text-decoration: none;
        font-weight: 700
    }
    #our-people-left {
        width: 90%;
        padding: 20px;
        background-color: #C5BDAE;
        float: left;
        margin-right: 40px;
        text-align: right;
        margin-bottom: 50px;
        margin-top: 40px
    }
    hr {
        color: #000;
        background-color: #000;
        border: none;
        height: 1px
    }
    #our-people-left img {
        float: left;
        width: 100%;
        height: auto;
        margin-bottom: 15px
    }
    #vcard {
        float: right;
        width: 75px;
        height: 30px;
        margin-left: 150px;
        margin-bottom: 20px
    }
    #vcard-white {
        float: left;
        width: 75px;
        height: 30px;
        margin: 3px 30px 0 0;
        text-decoration: underline
    }
    .plus {
        width: 30px;
        height: 30px;
        font-size: 50px;
        position: absolute;
        bottom: 35px;
        right: 15px;
        font-family: "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", "DejaVu Sans", Verdana, sans-serif;
        font-weight: 700
    }
    .plus a:link {
        color: #ffffff!important;
        text-decoration: none
    }
    .plus a:visited {
        color: #ffffff!important;
        text-decoration: none
    }
    .plus a:hover {
        color: #910008!important;
        text-decoration: none
    }
    .plus2 {
        width: 30px;
        height: 30px;
        font-size: 50px;
        position: absolute;
        bottom: 35px;
        right: 15px;
        font-family: "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", "DejaVu Sans", Verdana, sans-serif;
        font-weight: 700
    }
    .plus2 a:link {
        color: #ffffff!important;
        text-decoration: none
    }
    .plus2 a:visited {
        color: #ffffff!important;
        text-decoration: none
    }
    .plus2 a:hover {
        color: #C5BDAE!important;
        text-decoration: none
    }
    #our-people-content {
        width: 100%;
        float: left;
        padding-top: 40px
    }
    #subnav {
        width: 260px;
        padding: 135px 35px;
        background-color: #910008;
        float: right;
        text-align: right;
        background-image: url(images/bg-content.jpg);
        background-repeat: no-repeat;
        background-position: right top;
        position: relative;
        margin-right: 70px
    }
    #menu-our-people li {
        font-size: 18px;
        color: #FFF;
        font-family: 'Archivo Narrow', sans-serif;
        margin: 0
    }
    #subnav h2 {
        font-size: 22px;
        color: #000;
        font-family: 'Oswald', sans-serif
    }
    #menu-our-people a:link {
        color: #FFF;
        text-decoration: none
    }
    #menu-our-people a:visited {
        color: #FFF;
        text-decoration: none
    }
    #menu-our-people a:hover {
        color: #C5BDAE;
        text-decoration: none
    }
    #menu-our-people .current-menu-item a:link {
        color: #C5BDAE;
        text-decoration: none;
        font-weight: 700
    }
    #menu-our-people .current-menu-item a:visited {
        color: #C5BDAE;
        text-decoration: none;
        font-weight: 700
    }
    #subnav ul {
        margin: 0;
        padding: 0
    }
    #subnav li {
        list-style-type: none;
        margin: 0;
        padding: 0
    }
    #services-content-left {
        width: 100%;
        float: left
    }
    #services-our-people {
        width: 100%;
        float: left;
        margin-right: 0;
        margin-bottom: 33px
    }
    .services-our-people-box {
        width: 100%;
        height: auto;
        float: left;
        background-color: #C5BDAE;
        padding: 14px 14px 5px 14px;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        position: relative;
        margin-bottom: 7px
    }
    .services-our-people-image {
        float: left;
        width: 103px;
        height: 128px;
        padding-right: 20px
    }
    .services-our-people-box img {
        float: left;
        width: 103px;
        height: auto;
        max-height: 128px
    }
    #content-callout {
        height: 0;
        visibility: hidden
    }
    #content-callout-show {
        width: 100%;
        min-height: 160px;
        float: left;
        padding: 22px;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        background-color: #910008;
        position: relative
    }
    #content-callout-show .plus2 {
        bottom: 45px!important;
        right: 25px!important
    }
    #content-callout-show h2 {
        font-size: 22px;
        color: #fff;
        font-family: 'Oswald', sans-serif
    }
    #callout-box-line {
        width: 100%;
        position: absolute;
        bottom: 15px;
        border-bottom: 1px solid #000;
        padding-top: 10px
    }
    .post {
        width: 100%;
        float: left
    }
    #portfolio {
        width: 100%;
        float: left
    }
    #portfolio img {
        width: 150px;
        height: auto;
        float: left
    }
    .portfolio-item {
        background-color: #C5BDAE;
        padding: 20px;
        width: 100%;
        height: 142px;
        float: left;
        margin-right: 37px;
        margin-bottom: 33px;
        padding: 14px;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        position: relative
    }
    .portfolio-image {
        float: left;
        width: 155px;
        height: 112px;
        padding-right: 20px
    }
    .pp_play {
        visibility: hidden
    }
    #news-box {
        width: 100%;
        float: right;
        padding: 20px;
        border: 1px solid silver;
        margin-left: 110px;
        margin-right: 90px;
        margin-bottom: 20px
    }
    #news-box ul {
        list-style-type: none!important;
        padding-left: 0;
        margin-left: 0;
        margin-top: 10px
    }
    #news-box li {
        list-style-type: none!important;
        padding-left: 0;
        margin-bottom: 15px
    }
}
.form-group {
    position: relative;
    .form-control {
        width: 100%;
        height: 34px;
        line-height: 34px;
        box-sizing: border-box;
        padding: 10px;
        outline: none;
        border:1px solid #7e7e7e;
        @include transition(all ease-out .3s);
    }
    .form-control:focus {
        border: 1px solid #000;
    }
    .inputdiv {
        position: relative;
        width: 100%;
    }
}
.wrapper {
    max-width: 1000px;
    width: 100%;
}
.sp_form {
    margin-top: 25px;
    .row {
        margin-left: -10px;
        margin-right: -10px;
    }
}
.search-properties-form {
    .form-group {
        width: 33.333333%;
        float: left;
        padding-left: 10px;
        padding-right: 10px;
        box-sizing: border-box;
        margin-bottom: 20px;
    }
    
    .fieldwrap .btn {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 3;
        cursor: pointer;
        text-decoration: none;
        height: 100%;
        background: #969696;
        text-transform: uppercase;
        font-size: 14px;
        padding-left: 10px;
        padding-right: 10px;
        line-height: 34px;
        color: #FFF;
        @include transition(all ease-out .3s);
        &:hover {
            background: #6d6d6d;
        }
    }
    .selection-field {
        .fs-wrap,
        .fs-label-wrap {
            max-width: 100%;
            width: 100%;
        }
        .fs-wrap {
            position: relative;
            outline: none;
        }
        .fs-dropdown {
            width: 100%;
            margin-top: 0;
        }
        .fs-label-wrap {
            border:1px solid #7e7e7e;
        }
        .fs-label-wrap .fs-label {
            padding: 0 22px 0 8px;
            height: 34px;
            line-height: 34px;
        }
        .fs-wrap.multiple .fs-option.selected .fs-checkbox i {
            background-color:$color-red; 
        }
    }
    
    .reset-btn {
        -webkit-appearance:none;
        border-radius: 0;
        box-shadow: none;
        background: #969696;
        color:#FFF;
        height: 34px;
        line-height: 34px;
        border: none;
        width: 100px;
        padding: 0 0;
    }
}

.property-lists {
    margin-top: 20px;
    position: relative;
    .found-info {
        margin-bottom: 12px;
    }
    .all-list {
        margin-top: 20px;
    }
    .property {
        padding:20px;
        background: #f5f4f4;
        border:1px solid #e8e6e6;
        margin-top: 20px;
        position: relative;
    }
    .property.first {
         margin-top: 0;
    }
    .imagecol,
    .location,
    .features,
    .details {
        position: relative;
        float: left;
    }
    .imagecol {
        width: 30%;
        img {
            width: 100%;
            height: auto;
            float: left;
        }
        a.popup {
            display:block;
            float: left;
        }
        .types {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 3;
            box-sizing: border-box;
            width: 100%;
            padding:5px 8px;
            background: $color-red;
            color: #FFF;
        }
    }
    .infoCol {
        width: 67%;
        float: right;
    }
    .location,
    .features {
        hyphens: none;
        .pad {
            padding-left: 15px;
            padding-right: 15px;
        }
        .info {
            margin-bottom: 15px;
        }
    }
    .location {
        width: 35%;
    }
    .features {
        width: 40%;
        .pad {
            padding-left: 0;
        }
        .info {
            line-height: 1.2;
        }
        .city {
            font-weight: bold;
            font-size: 20px;
        }
    }
    .details {
        width: 25%;
        hyphens: none;
    }
    .property-name {
        margin: 0 0 15px;
        color:$color-red;
        font-size: 20px;
    }
    .plink {
        display: block;
        padding:10px 20px;
        width: 100%;
        box-sizing: border-box;
        background: #3F5BA9;
        color:#FFF;
        text-decoration: none;
        text-align: center;
        text-transform: uppercase;
        font-size:13px;
        @include transition(all ease-out .2s);
        &:hover {
            background: #263a71;
        }
    }
    .details.has-broker .plink {
        margin-bottom: 15px;
    }
    .broker {
        margin-top: 18px;
    }
    .broker-info {
        margin-top: 4px;
        margin-bottom: 4px;
        padding-left: 20px;
        position: relative;
        .icon {
            position: absolute;
            top: 3px;
            left: 0;
            font-size: 13px;
            color: #9e9e9e;
        }
        &.name {
            .icon {
                top: 2px;
            }
        }
        &.email .icon {
            font-size: 12px;
        }
        a {
            text-decoration: none;
        }
    }
}


#pagination {
    margin-top:30px;
    a, span {
        display: inline-block;
        text-decoration: none;
        border: 1px solid #ccc;
        padding: 6px 12px;
        margin-right: 1px;
        @include transition(all ease-out .2s);
    }
    a:hover {
        border-color: $color-red;
        color:$color-red;
    }
    .current {
        background: $color-red;
        border-color: $color-red;
        color:#FFF;
    }
}
.notfound {
    border: 1px solid $color-red;
    color:$color-red;
    padding:10px 20px;
    font-size: 20px;
    background: #fcecee;
    margin-top: 20px;
}
#pagination ul.pagination {
    margin: 0 0;
    padding: 0 0;
    list-style: none;
    li {
        background: none;
        padding-left: 0;
        display: inline-block;
        float: left;
        margin-right: 4px;
        position: relative;
        a {
            @include transition(all ease-out .2s);
        }
        &.active a {
            background: $color-red;
            border-color: $color-red;
            color:#FFF;
            &:hover {
                color:#FFF;
            }
        }
        span.cover {
            display: none;
        }
        &.disabled, &.active {
            span.cover {
                display: block;
                width: 100%;
                height: 100%;
                background: #f0efef;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 2;
                opacity: 0.75;
                padding: 0 0;
                border:none;
            }
        }
        &.active span.cover {
            opacity: 0;
        }
    }
}

.spinner-wrapper,
.listing-outer-wrapper,
.sp-form-wrapper,
#mainContent,
#list_container {
    position: relative;
}
.spinner-wrapper {
    width: 100%;
}
#listingFilter {
    position: relative;
    width: 100%;
    .cover {
        display: none;
    }
    &.disable-form .cover {
        display: block;
        position: absolute;
        top: -12px;
        left: 0;
        width: 100%;
        height: 105%;
        background: #FFF;
        opacity: 0.5;
        z-index: 100;
    }
}
#spinner {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 500;
    text-align: center;
    display: none;
}
.sk-circle {
    height: 40px;
    width: 40px;
    right: 0;
    left: 0;
    z-index: 10;
    margin-right: 40px;
    margin-top: -30px;
    display: inline-block;
    transition: all 0.2s ease-in-out 0;
    position: relative;
}
.sk-circle .sk-child {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0
}

.sk-circle .sk-child:before {
    content: '';
    display: block;
    margin: 0 auto;
    width: 15%;
    height: 15%;
    background-color:$color-red;
    border-radius: 100%;
    -webkit-animation: sk-circleBounceDelay 1s infinite ease-in-out both;
    animation:sk-circleBounceDelay 1s infinite ease-in-out both;
}

.sk-circle .sk-circle2 {
    -webkit-transform: rotate(30deg);
    -ms-transform: rotate(30deg);
    transform: rotate(30deg)
}

.sk-circle .sk-circle3 {
    -webkit-transform: rotate(60deg);
    -ms-transform: rotate(60deg);
    transform: rotate(60deg)
}

.sk-circle .sk-circle4 {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg)
}

.sk-circle .sk-circle5 {
    -webkit-transform: rotate(120deg);
    -ms-transform: rotate(120deg);
    transform: rotate(120deg)
}

.sk-circle .sk-circle6 {
    -webkit-transform: rotate(150deg);
    -ms-transform: rotate(150deg);
    transform: rotate(150deg)
}

.sk-circle .sk-circle7 {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg)
}

.sk-circle .sk-circle8 {
    -webkit-transform: rotate(210deg);
    -ms-transform: rotate(210deg);
    transform: rotate(210deg)
}

.sk-circle .sk-circle9 {
    -webkit-transform: rotate(240deg);
    -ms-transform: rotate(240deg);
    transform: rotate(240deg)
}

.sk-circle .sk-circle10 {
    -webkit-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg)
}

.sk-circle .sk-circle11 {
    -webkit-transform: rotate(300deg);
    -ms-transform: rotate(300deg);
    transform: rotate(300deg)
}

.sk-circle .sk-circle12 {
    -webkit-transform: rotate(330deg);
    -ms-transform: rotate(330deg);
    transform: rotate(330deg)
}

.sk-circle .sk-circle2:before {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s
}

.sk-circle .sk-circle3:before {
    -webkit-animation-delay: -1s;
    animation-delay: -1s
}

.sk-circle .sk-circle4:before {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s
}

.sk-circle .sk-circle5:before {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s
}

.sk-circle .sk-circle6:before {
    -webkit-animation-delay: -0.7s;
    animation-delay: -0.7s
}

.sk-circle .sk-circle7:before {
    -webkit-animation-delay: -0.6s;
    animation-delay: -0.6s
}

.sk-circle .sk-circle8:before {
    -webkit-animation-delay: -0.5s;
    animation-delay: -0.5s
}

.sk-circle .sk-circle9:before {
    -webkit-animation-delay: -0.4s;
    animation-delay: -0.4s
}

.sk-circle .sk-circle10:before {
    -webkit-animation-delay: -0.3s;
    animation-delay: -0.3s
}

.sk-circle .sk-circle11:before {
    -webkit-animation-delay: -0.2s;
    animation-delay: -0.2s
}

.sk-circle .sk-circle12:before {
    -webkit-animation-delay: -0.1s;
    animation-delay: -0.1s
}
@-webkit-keyframes sk-circleBounceDelay {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0)
    }
    40% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }
}

@keyframes sk-circleBounceDelay {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0)
    }
    40% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }
}
#mainContent.page-content {
    padding-left: 0;
    margin: 0 auto;
    float: none;
    background: none;
}
.contentwrapper {
    background-image: url("images/bg-content-bottom.jpg");
    background-repeat: no-repeat;
    background-position: left bottom;
}
@media screen and (max-width: $midwidth) {
    #mainContent.page-content {
        width: 100%;
        padding-left: 30px;
        padding-right: 30px;
        box-sizing: border-box;
    }
    .property-lists .plink {
        width: 100%;
    }
    .property-lists .imagecol {
        width: 28%;
    }
    .property-lists .infoCol {
        width: 71%;
    }
}
@media screen and (max-width: $tabwidth) {
    #navigation {
        display: none;
    }
    .property-lists .imagecol {
        width: 75%;
        margin: 0 auto;
        float: none;
    }
    .property-lists .infoCol {
        width: 100%;
        float: left;
        margin-top: 30px;
        .details {
            width: 100%;
            margin-top: 15px;
        }
        .location,
        .features {
            width: 50%;
        }
        .features .pad {
            padding-right: 0;
        }
        .location .pad {
            padding-left: 0;
        }
    }
    .property-lists .plink {
        margin: 0 auto;
        width: 100%;
    }
    .search-properties-form .form-group {
        width: 50%;
    }
}

@media screen and (max-width: 480px) {
    .property-lists .imagecol {
        width: 100%;
        float: left;
    }
    .property-lists .infoCol {
        .location,
        .features {
            width: 100%;
            text-align: center;
            .pad {
                padding-left: 0;
                padding-right: 0;
            }
        }
        .location {
            margin-bottom: 20px;
        }
    }
    .search-properties-form .form-group {
        width: 100%;
    }
}

select.mdb-select {
    display: none;
}

#colorbox #cboxTitle {
    color:$color-red;
}

