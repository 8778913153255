/*
    Colorbox Core Style:
    The following CSS is consistent between example themes and should not be altered.
*/
#colorbox, #cboxOverlay, #cboxWrapper{position:absolute; top:0; left:0; z-index:9999; overflow:hidden;}
#cboxWrapper {max-width:none;}
#cboxOverlay{position:fixed; width:100%; height:100%;}
#cboxMiddleLeft, #cboxBottomLeft{clear:left;}
#cboxContent{position:relative;}
#cboxLoadedContent{overflow:auto; -webkit-overflow-scrolling: touch;}
#cboxTitle{margin:0;box-size:border-box}
#cboxLoadingOverlay, #cboxLoadingGraphic{position:absolute; top:0; left:0; width:100%; height:100%;}
#cboxPrevious, #cboxNext, #cboxClose, #cboxSlideshow{cursor:pointer;}
.cboxPhoto{
	float:left; 
	margin:auto; 
	border:0; 
	display:block; 
	max-width:none; 
	-ms-interpolation-mode:bicubic;
	width: 80%;
	height: 80%;
	}
.cboxIframe{width:100%; height:100%; display:block; border:0; padding:0; margin:0;}
#colorbox, #cboxContent, #cboxLoadedContent{box-sizing:content-box; -moz-box-sizing:content-box; -webkit-box-sizing:content-box;}

/* 
    User Style:
    Change the following styles to modify the appearance of Colorbox.  They are
    ordered & tabbed in a way that represents the nesting of the generated HTML.
*/
#cboxOverlay{background:#000;}
#colorbox{outline:0;}
   /* #cboxTopLeft{width:14px; height:14px; background:url(images/controls.png) no-repeat 0 0;}
    #cboxTopCenter{height:14px; background:url(images/border.png) repeat-x top left;}
    #cboxTopRight{width:14px; height:14px; background:url(images/controls.png) no-repeat -36px 0;}
    #cboxBottomLeft{width:14px; height:43px; background:url(images/controls.png) no-repeat 0 -32px;}
    #cboxBottomCenter{height:43px; background:url(images/border.png) repeat-x bottom left;}
    #cboxBottomRight{width:14px; height:43px; background:url(images/controls.png) no-repeat -36px -32px;}
    #cboxMiddleLeft{width:14px; background:url(images/controls.png) repeat-y -175px 0;}
    #cboxMiddleRight{width:14px; background:url(images/controls.png) repeat-y -211px 0;}*/
    #cboxContent{/*background:#fff; */overflow:visible;}
        .cboxIframe{background:#fff;}
        #cboxError{padding:50px; border:1px solid #ccc;}
        #cboxLoadedContent{
			/*margin-bottom:5px;*/
			
			}
        #cboxLoadingOverlay{background:url(images/loading_background.png) no-repeat center center;}
        #cboxLoadingGraphic{background:url(images/loading.gif) no-repeat center center;}
        #cboxTitle{
			position:absolute; 
			bottom:-25px; 
			left:0; 
			text-align:left; 
			width:60%; 
			font-weight:bold; 
			color:#fff;
			/*background-color: rgba(0,0,0,.6);*/
			padding: 2% 5%;
			line-height: 1.3;
			font-size: 16px;
            box-size:border-box;
			}
        #cboxCurrent{position:absolute; bottom:-25px; left:58px; font-weight:bold; color:#7C7C7C;}

        /* these elements are buttons, and may need to have additional styles reset to avoid unwanted base styles */
        #cboxPrevious, #cboxNext, #cboxSlideshow, #cboxClose {
			border:0; 
			padding:0; 
			margin:0; 
			overflow:visible;  
			position:absolute; 
			top:10px; 
			background:url(images/controls-new.png); 
			background-size: 150px 100px;
			background-repeat: no-repeat;
			width:50px; 
			height:50px; 
			text-indent:-9999px;
			box-shadow:none;
		}
        
        /* avoid outlines on :active (mouseclick), but preserve outlines on :focus (tabbed navigating) */
        #cboxPrevious:active, #cboxNext:active, #cboxSlideshow:active, #cboxClose:active {outline:0;}

        #cboxPrevious{left:20px; background-position: -0px -0px;}
        #cboxPrevious:hover{background-position:-0px -50px;}
        #cboxNext{left:90px; background-position:-50px -0px;}
        #cboxNext:hover{background-position:-50px -50px;}
        #cboxClose{right:20px; background-position:-100px -0px;}
        #cboxClose:hover{background-position:-100px -50px;}

        .cboxSlideshow_on #cboxSlideshow{background-position:-125px 0px; right:27px;}
        .cboxSlideshow_on #cboxSlideshow:hover{background-position:-150px 0px;}
        .cboxSlideshow_off #cboxSlideshow{background-position:-150px -25px; right:27px;}
        .cboxSlideshow_off #cboxSlideshow:hover{background-position:-125px 0px;}
@media screen and (min-width: 600px) {
	#cboxTitle{
			position:absolute; 
			left:0; 
			text-align:left; 
			width:20%; 
			font-weight:bold; 
			color:#fff;
			/*background-color: rgba(0,0,0,.6);*/
			padding: 0 0;
			line-height: 1.3;
			}
}
@media screen and (min-width: 1140px) {
}