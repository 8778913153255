.fs-wrap {
    display: inline-block;
    cursor: pointer;
    line-height: 1;
    width: 200px;
}

.fs-label-wrap {
    position: relative;
    background-color: #fff;
    border: 1px solid #ddd;
    cursor: default;
}

.fs-label-wrap,
.fs-dropdown {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.fs-label-wrap .fs-label {
    padding: 6px 22px 6px 8px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.fs-arrow {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #333;
    position: absolute;
    top: 0;
    right: 5px;
    bottom: 0;
    margin: auto;
}

.fs-dropdown {
    position: absolute;
    background-color: #fff;
    border: 1px solid #ddd;
    width: 200px;
    margin-top: 5px;
    z-index: 1000;
}

.fs-dropdown .fs-options {
    max-height: 200px;
    overflow: auto;
}

.fs-search input {
    border: none !important;
    box-shadow: none !important;
    outline: none;
    padding: 6px 0;
    width: 100%;
}

.fs-option,
.fs-search,
.fs-optgroup-label {
    padding: 6px 8px;
    border-bottom: 1px solid #eee;
    cursor: default;
}

.fs-option:last-child {
    border-bottom: none;
}

.fs-search {
    padding: 0 8px;
}

.fs-no-results {
    padding: 6px 8px;
}

.fs-option {
    cursor: pointer;
    word-break: break-all;
}

.fs-option.disabled {
    opacity: 0.4;
    cursor: default;
}

.fs-option.hl {
    background-color: #f5f5f5;
}

.fs-wrap.multiple .fs-option {
    position: relative;
    padding-left: 30px;
}

.fs-wrap.multiple .fs-checkbox {
    position: absolute;
    display: block;
    width: 30px;
    top: 0;
    left: 0;
    bottom: 0;
}

.fs-wrap.multiple .fs-option .fs-checkbox i {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 14px;
    height: 14px;
    border: 1px solid #aeaeae;
    border-radius: 2px;
    background-color: #fff;
}

.fs-wrap.multiple .fs-option.selected .fs-checkbox i {
    background-color: rgb(17, 169, 17);
    border-color: transparent;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAGCAYAAAD+Bd/7AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEgAACxIB0t1+/AAAABx0RVh0U29mdHdhcmUAQWRvYmUgRmlyZXdvcmtzIENTNXG14zYAAABMSURBVAiZfc0xDkAAFIPhd2Kr1WRjcAExuIgzGUTIZ/AkImjSofnbNBAfHvzAHjOKNzhiQ42IDFXCDivaaxAJd0xYshT3QqBxqnxeHvhunpu23xnmAAAAAElFTkSuQmCC');
    background-repeat: no-repeat;
    background-position: center;
}

.fs-optgroup-label {
    font-weight: bold;
    text-align: center;
}

.hidden {
    display: none;
}
